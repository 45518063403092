import { Injectable, NgZone } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token, } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Client, UserDeviceDataResponse, UserDeviceRequest } from '../api-clients/pyjam/client';
import { PlatformService } from './platform.service';
import CallKit, { Order } from '../plugins/android_callkit/callkit.plugin';
import { PhoneInfoService } from '../avatar/services/phone-info.service';
import { catchError, firstValueFrom, take, tap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _userDeviceId = null;
  private readonly isPushNotificationsAvailable: boolean;

  constructor(
    private router: Router,
    private afMessaging: AngularFireMessaging,
    private client: Client,
    private platformService: PlatformService,
    private phoneInfoService: PhoneInfoService,
    private ngZone: NgZone,
  ) {
    this.isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

    if (!this.isPushNotificationsAvailable && 'serviceWorker' in navigator) {
      this.addServiceWorkerListeners();
    }
  }

  public async initializePushNotifications(): Promise<void> {
    await this.phoneInfoService.getDeviceModel();

    if (this.isPushNotificationsAvailable) {
      await this.initPushNotifications();
    } else {
      this.initFirebaseMessaging();
    }
  }

  public getUserDeviceId(): number {
    return this._userDeviceId;
  }

  public async deleteUserDevice(): Promise<void> {
    if (!this.userDeviceId) return console.error('User device ID is not defined!');

    try {
      await firstValueFrom(this.client.userDeviceDelete(this.userDeviceId));
      this.userDeviceId = null;
    } catch (error) {
      console.error('Error on delete user device', error);
    }
  }

  private set userDeviceId(userDeviceId: number) {
    this._userDeviceId = userDeviceId;
  }

  private get userDeviceId() {
    return this._userDeviceId;
  }

  private addServiceWorkerListeners(): void {
    if (this.isPushNotificationsAvailable || !('serviceWorker' in navigator)) return;

    // Listen to service worker messages sent via postMessage()
    navigator.serviceWorker.addEventListener('message', async (event): Promise<void> => {
      if (!event.data) return console.error('[ServiceWorker] message data is not defined!');

      console.log('\x1b[35m' + `[ServiceWorker] message:` + '\x1b[0m', event.data);

      await this.navigate(event.data);
    });
  }

  private async initPushNotifications(): Promise<void> {
    if (this.platformService.isDevice && this.platformService.isAndroid) {
      await this.createNotificationChannels();
    }
    await this.addNotificationsListeners();
    await this.requestsNotificationsPermissions();
  }

  private async createNotificationChannels(): Promise<void> {
    if (!this.platformService.isDevice || !this.platformService.isAndroid) return;

    try {
      await PushNotifications.createChannel({
        id: 'sound-vibration-channel',
        name: 'Sound and vibration',
        description: 'Channel for notifications with sound and vibration',
        sound: 'default',
        importance: 5,
        vibration: true,
        visibility: 1,
        lights: true,
      });

      await PushNotifications.createChannel({
        id: 'call-sound-vibration-channel',
        name: 'Call sound and vibration',
        description: 'Channel for call notifications with sound and vibration',
        sound: 'avatar2',
        importance: 5,
        vibration: true,
        visibility: 1,
        lights: true,
      });
    } catch (error) {
      console.error('Error on create notification channels', error);
    }
  }

  private async addNotificationsListeners(): Promise<void> {
    try {
      await PushNotifications.removeAllListeners();
      await LocalNotifications.removeAllListeners();

      // On success, we should be able to receive notifications
      await PushNotifications.addListener('registration', async (token: Token): Promise<void> => {
        // alert(`Push registration, token: ${JSON.stringify(token.value)}`);
        // console.log(`Push registration, token: ${token.value}`);

        await this.userDeviceSend(token.value);
      });

      // Some issue with our setup and push will not work
      await PushNotifications.addListener('registrationError', (error: any): void => {
        // alert(`Error push registration: ${JSON.stringify(error)}`);
        console.error(`Error push registration, error: ${error}`);
      });

      // Show us the notification payload if the app is open on our device
      await PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema): Promise<void> => {
        // alert(`Push notification received: ${JSON.stringify(notification)}`);
        console.log(`Push notification received, notification: ${notification}`);

        await this.scheduleNotification(notification);
      });

      // Method called when tapping on a notification
      await PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed): Promise<void> => {
        // alert(`Push action performed: ${JSON.stringify(notification)}`);
        console.log(`Push action performed, notification: ${notification}`);

        await this.navigate(notification.notification.data);
      });

      // Show us the local notification payload if the app is open on our device
      await LocalNotifications.addListener('localNotificationReceived', (notification): void => {
        // alert(`Local notification received: ${JSON.stringify(notification)}`);
        console.log(`Local notification received: ${notification}`);
      });

      // Method called when tapping on a local notification
      await LocalNotifications.addListener('localNotificationActionPerformed', async (notification): Promise<void> => {
        // alert(`Local notification received: ${JSON.stringify(notification)}`);
        console.log(`Local notification received: ${notification}`);

        await this.navigate(notification.notification.extra);
      });

      await CallKit.addListener('answer', async (order: Order): Promise<void> => {
        if (!order) return console.error('CallKit answer. No order!');

        // alert(`CallKit answer, order ID: ${JSON.stringify(order)}`);
        console.log(`CallKit answer, order ID: ${order}`);

        await this.goToOrderDetails(order.id);
      });
    } catch (error) {
      console.error('Error on add notifications listeners', error);
    }
  }

  private async scheduleNotification(notification: PushNotificationSchema): Promise<void> {
    try {
      // Create a local notification
      await LocalNotifications.schedule({
        notifications: [{
          title: notification.title,
          body: notification.body,
          extra: notification.data,
          id: Math.round(Math.random() * 10000), // Use a unique ID for each notification
          smallIcon: 'ic_stat_onesignal_default',
          iconColor: this.isDarkTheme() ? '#ffffff' : '#09203e',
          // Add any other desired options for the notification
        }]
      });
    } catch (error) {
      console.error('Error on schedule notification', error);
    }
  }

  private isDarkTheme(): boolean {
    return document.body.classList.contains('dark');
  }

  private async requestsNotificationsPermissions(): Promise<void> {
    // Request permission to use push notifications
    try {
      const result = await PushNotifications.requestPermissions();

      if (result.receive === 'granted') {
        console.log('Push notification permission:', result.receive);
        // Register with Apple / Google to receive push via APNS/FCM
        await PushNotifications.register();
      } else {
        // Show some error
        console.warn('Push notification permission:', result.receive);
      }
    } catch (error) {
      console.error('Error on request Push notification permissions', error);
    }

    // Request permission to show local notifications
    try {
      const result = await LocalNotifications.requestPermissions();

      if (result.display === 'granted') {
        console.log('Local notification permission:', result.display);
      } else {
        console.warn('Local notification permission:', result.display);
      }
    } catch (error) {
      console.error('Error on request Local notification permissions', error);
    }
  }

  private initFirebaseMessaging(): void {
    this.afMessaging.requestToken.pipe(
      take(1),
      tap(async (token: string): Promise<void> => {
        token
          ? await this.registerUserDevice(token)
          : await this.deleteUserDevice();
      }),
      catchError(async (error): Promise<void> => {
        console.error(error);
      }),
    ).subscribe();
  }

  private async registerUserDevice(token: string): Promise<void> {
    if (!token) return console.error('Token is not defined!');

    // alert(`AFS, user device registration, token: ${JSON.stringify(token)}`);
    // console.log(`AFS, user device registration, token: ${token}`);

    await this.userDeviceSend(token);

    try {
      await this.afMessaging.onMessage((message): void => {
        let notificationOptions: NotificationOptions = {
          body: message.data.body,
          icon: '/assets/icon/logo.png',
          data: message.data,
        };
        let notification: Notification = new Notification(message.data.title, notificationOptions);

        notification.addEventListener('click', async (event: Event): Promise<void> => {
          await this.navigate((event.target as Notification).data);
        });
      });
    } catch (error) {
      console.error('Error on register user device', error);
    }
  }

  private async userDeviceSend(token: string): Promise<void> {
    if (!token) return console.error('Token is not defined!');

    try {
      const request: UserDeviceRequest = {
        token,
        name: this.phoneInfoService.phoneModelWithOS
      } as UserDeviceRequest;

      console.log('Send device PUSH token:', token);
      const response: UserDeviceDataResponse = await firstValueFrom(this.client.userDevicePost(request));
      console.log('Received device ID:', response.data.id);
      this.userDeviceId = response.data.id;
    } catch (error) {
      console.error('Error on registration user device', error);
    }
  }

  private async navigate(data: any): Promise<void> {
    if (!data) return console.error('Data is not defined!');

    switch (Number(data.type)) {
      case 1:
        if (!this.isPushNotificationsAvailable) return;
        if (!data?.chatId) return console.error('chatId is not defined!');
        try {
          const deliveredNotifications = await PushNotifications.getDeliveredNotifications();
          deliveredNotifications.notifications = deliveredNotifications.notifications
            .map((deliveredNotification) => data.title == deliveredNotification.title ? deliveredNotification : null)
            .filter(Boolean);

          await PushNotifications.removeDeliveredNotifications(deliveredNotifications);
        } catch (error) {
          console.error('Error on remove delivered notifications', error);
        } finally {
          await this.ngZone.run(async (): Promise<void> => {
            await this.router.navigate(['chats', data.chatId]);
          });
        }
        break;
      case 4:
      case 6:
      case 8:
      case 18:
      case 19:
      case 20:
      case 21:
      case 22:
      case 23:
      case 24:
      case 26:
      case 27:
      case 28:
      case 29:
      case 32:
      case 33:
        if (!data?.chatId) return console.error('chatId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['chats', data.chatId]);
        });
        break;
      case 2:
        if (!data?.taskId) return console.error('taskId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['tasks', data.taskId, 'comments']);
        });
        break;
      case 3:
      case 5:
      case 11:
      case 12:
      case 13:
      case 14:
      case 17:
      case 25:
        if (!data?.replyId) return console.error('replyId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['reply', data.replyId]);
        });
        break;
      case 7:
      case 15:
      case 16:
      case 34:
        if (!data?.taskId) return console.error('taskId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['tasks', data.taskId]);
        });
        break;
      case 9:
      case 31:
        if (!data?.paymentId) return console.error('paymentId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['profile/balance']);
        });
        break;
      case 10:
        // if (!data?.onboardingId) return console.error('onboardingId is not defined!');
        //
        // await this.ngZone.run(async (): Promise<void> => {
        //   await this.router.navigate(['profile/balance']);
        // });
        break;
      case 30:
        if (!data?.categoryId) return console.error('categoryId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['tasks/add/category']);
        });
        break;
      case 39:
        if (!data?.avatarOrderId) return console.error('avatarOrderId is not defined!');

        await this.goToOrderDetails(data.avatarOrderId);
        break;
    }
  }

  private async goToOrderDetails(orderId: number): Promise<void> {
    console.log('Notification service, call push, orderId', orderId);

    await this.ngZone.run(async (): Promise<void> => {
      await this.router.navigate(['avatar/order-details/', orderId]);
    });
  }
}
